import { useState } from 'react'

import { RadioGroup } from '@headlessui/react'

import {
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
  PhoneIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline'

import * as HoverCard from '@radix-ui/react-hover-card'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

import { Container } from '@/components/Container'
import { Logomark } from '@/components/Logo'
import { ENV } from '@/constants/env'
import { IDS } from '@/constants/ids'
import { Icon } from '@/models'

type Tier = 'starter' | 'salon' | 'business'

type Feature = {
  title: string
  description?: string
  tiers: {
    title: Tier
    value?: React.ReactNode
    isFeatured?: boolean
  }[]
}

type FeaturesGroups = {
  category: {
    title: string
    id: string
  }
  features: Feature[]
}[]

type Plan = {
  name: string
  featured: boolean
  compared: boolean
  description: string
  price: { monthly: string; annually: string }
  netPrice: { monthly: string; annually: string }
  button: { label: string; href: string }
  mainFeatures?: { id: number; value: string }[]
}

export const Table = ({ plans }: { plans: Plan[] }) => {
  const { t } = useTranslation('pricing')

  const featuresGroups: FeaturesGroups = [
    {
      category: {
        id: IDS.PRICING_MANAGEMENT,
        title: t('pricing.groups.management.title'),
      },
      features: [
        {
          title: t('pricing.features.clients.title'),
          description: t('pricing.features.clients.description'),
          tiers: [
            {
              title: 'starter',
              value: t('pricing.features.unlimited'),
            },
            {
              title: 'salon',
              value: t('pricing.features.unlimited'),
            },
          ],
        },
        {
          title: t('pricing.features.treatments.title'),
          description: t('pricing.features.treatments.description'),
          tiers: [
            {
              title: 'starter',
              value: t('pricing.features.unlimited'),
            },
            {
              title: 'salon',
              value: t('pricing.features.unlimited'),
            },
          ],
        },
        {
          title: t('pricing.features.appointments.title'),
          tiers: [
            {
              title: 'starter',
              value: '50',
            },
            {
              title: 'salon',
              value: t('pricing.features.unlimited'),
            },
          ],
        },
        {
          title: t('pricing.features.employees.title'),
          description: t('pricing.features.employees.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-red-400">
                  <FeatureIcon icon={XMarkIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: '6',
            },
          ],
        },
        {
          title: t('pricing.features.calendar.title'),
          description: t('pricing.features.calendar.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.statistics.title'),
          description: t('pricing.features.statistics.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.selfBooking.title'),
          description: t('pricing.features.selfBooking.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-red-400">
                  <FeatureIcon icon={XMarkIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      category: {
        title: t('pricing.groups.forClients.title'),
        id: IDS.PRICING_FOR_CLIENTS,
      },
      features: [
        {
          title: t('pricing.features.smsCanceling.title'),
          description: t('pricing.features.smsCanceling.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.smsNotifications.title'),
          description: t('pricing.features.smsNotifications.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      category: {
        id: IDS.PRICING_BRANDING,
        title: t('pricing.groups.branding.title'),
      },
      features: [
        {
          title: t('pricing.features.smsPrice.title'),
          tiers: [
            {
              title: 'starter',
              value: '19 gr',
            },
            {
              title: 'salon',
              value: '12 gr',
            },
          ],
        },
        {
          title: t('pricing.features.senderName.title'),
          description: t('pricing.features.senderName.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-red-400">
                  <FeatureIcon icon={XMarkIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.smsTemplates.title'),
          description: t('pricing.features.smsTemplates.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-red-400">
                  <FeatureIcon icon={XMarkIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
      ],
    },
    {
      category: {
        id: IDS.PRICING_COMFORT,
        title: t('pricing.groups.comfort.title'),
      },
      features: [
        {
          title: t('pricing.features.talks.title'),
          description: t('pricing.features.talks.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: t('pricing.features.talks.values.priority'),
            },
          ],
        },
        {
          title: t('pricing.features.gdpr.title'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.guaranteedPrice.title'),
          description: t('pricing.features.guaranteedPrice.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.privacy.title'),
          description: t('pricing.features.privacy.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.simplicity.title'),
          description: t('pricing.features.simplicity.description'),
          tiers: [
            {
              title: 'starter',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
            {
              title: 'salon',
              value: (
                <div className="text-teal-500">
                  <FeatureIcon icon={CheckIcon} />
                </div>
              ),
            },
          ],
        },
        {
          title: t('pricing.features.fees.title'),
          description: t('pricing.features.fees.description'),
          tiers: [
            {
              title: 'starter',
              value: '0%',
            },
            {
              title: 'salon',
              value: '0%',
            },
          ],
        },
        {
          title: t('pricing.features.creditCard.title'),
          description: t('pricing.features.creditCard.description'),
          tiers: [
            {
              title: 'starter',
              value: t('pricing.features.notRequired'),
            },
            {
              title: 'salon',
              value: t('pricing.features.notRequired'),
            },
          ],
        },
      ],
    },
  ]

  return (
    <div>
      {/* up to lg */}
      <section
        aria-labelledby="mobile-comparison-heading"
        className="block lg:hidden"
      >
        <div className="mx-auto mt-20 max-w-2xl text-center">
          <h2 className="text-3xl font-medium tracking-tight">
            {t('pricing.compareTitle')}
          </h2>
          <p className="mt-2 text-lg text-violet-950/80">
            {t('pricing.compareDescription')}
          </p>
        </div>
        <div className="mx-auto max-w-2xl">
          <div className="mt-16 border-t border-violet-300/50 pb-3 pt-4 ">
            <p className="text-md w-1/3 font-bold">
              {t('pricing.tiers.starter.name')}
            </p>
            <p className="w-100 text-sm sm:w-2/3 md:w-1/2">
              {t('pricing.tiers.starter.description')}
            </p>

            {featuresGroups.map((group) => (
              <div className="mt-4" key={group.category.title}>
                <p className="border-y border-violet-300/50 bg-violet-200/50 px-4 py-3 text-sm font-bold">
                  {group.category.title}
                </p>
                <div className="flex flex-col rounded-sm text-sm">
                  {group.features.map((feature) => (
                    <div
                      className="flex justify-between border-b border-violet-200 px-2 py-4 sm:p-4"
                      key={feature.title}
                    >
                      <HoverCard.Root>
                        <HoverCard.Trigger className="inline-flex items-center gap-1">
                          {feature.title}
                          <button>
                            {feature.description && (
                              <InformationCircleIcon className="h-4 w-4 text-violet-600" />
                            )}
                          </button>
                        </HoverCard.Trigger>
                        <HoverCard.Portal>
                          <HoverCard.Content className="max-w-sm rounded-sm bg-white p-4 text-violet-950/70 shadow">
                            {feature.description}
                          </HoverCard.Content>
                        </HoverCard.Portal>
                      </HoverCard.Root>
                      <div className="text-sm">{feature.tiers[0].value}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-16 border-t border-violet-300/50 pb-3 pt-4 ">
            <p className="text-md w-1/3 font-bold">
              {t('pricing.tiers.salon.name')}
            </p>
            <p className="w-100 text-sm sm:w-2/3 md:w-1/2">
              {t('pricing.tiers.salon.description')}
            </p>

            {featuresGroups.map((group) => (
              <div className="mt-4" key={group.category.title}>
                <p className="border-y border-violet-300/50 bg-violet-200/50 px-4 py-3 text-sm font-bold">
                  {group.category.title}
                </p>
                <div className="flex flex-col rounded-sm text-sm">
                  {group.features.map((feature) => (
                    <div
                      className="flex justify-between border-b border-violet-200 px-2 py-4 sm:p-4"
                      key={feature.title}
                    >
                      <HoverCard.Root>
                        <HoverCard.Trigger className="inline-flex items-center gap-1">
                          {feature.title}
                          <button>
                            {feature.description && (
                              <InformationCircleIcon className="h-4 w-4 text-violet-600" />
                            )}
                          </button>
                        </HoverCard.Trigger>
                        <HoverCard.Portal>
                          <HoverCard.Content className="max-w-sm rounded-sm bg-white p-4 text-violet-950/70 shadow">
                            {feature.description}
                          </HoverCard.Content>
                        </HoverCard.Portal>
                      </HoverCard.Root>
                      <div className="text-sm">{feature.tiers[1].value}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* lg+ */}
      <section className="hidden lg:block">
        <div className="mx-auto mt-20 max-w-2xl text-center">
          <h2
            id="pricing-title"
            className="text-3xl font-medium tracking-tight "
          >
            {t('pricing.compareTitle')}
          </h2>
          <p className="mt-2 text-lg text-violet-950/80">
            {t('pricing.compareDescription')}
          </p>
        </div>
        <div className="mx-auto max-w-7xl">
          <div className="mt-16 flex border-t border-violet-300/50 pb-3 pt-4 font-bold">
            <p className="ml-auto w-1/3 px-4 text-center text-lg">
              {t('pricing.tiers.starter.name')}
            </p>
            <p className="w-1/3 pl-4 text-center text-lg">
              {t('pricing.tiers.salon.name')}
            </p>
          </div>
          {featuresGroups.map((featureGroup) => (
            <div key={featureGroup.category.title}>
              <div
                className="w-100 -mt-px flex border-y border-violet-300/50 bg-violet-300/25 py-3 pr-4"
                id={featureGroup.category.id}
              >
                <h3 className="pl-4 text-sm font-bold">
                  {featureGroup.category.title}
                </h3>
              </div>
              <table className="relative w-full">
                <caption className="sr-only">
                  {t('pricing.screenReader.featureComparison')}
                </caption>
                <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">
                        {t('pricing.screenReader.feature')}
                      </span>
                    </th>
                    {plans.map(
                      (plan) =>
                        plan.compared && (
                          <th key={plan.name} scope="col">
                            <span className="sr-only">
                              {plan.name} {t('pricing.screenReader.plan')}
                            </span>
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-violet-200">
                  {featureGroup.features.map((feature) => (
                    <tr key={feature.title}>
                      <th
                        scope="row"
                        className="w-1/4 py-3 pl-4 pr-4 text-left text-sm font-medium text-violet-950/80"
                      >
                        <HoverCard.Root>
                          <HoverCard.Trigger>
                            <span className="inline-flex items-center gap-1">
                              {feature.title}
                              {feature.description && (
                                <InformationCircleIcon className="h-4 w-4 text-violet-600" />
                              )}
                            </span>
                          </HoverCard.Trigger>
                          <HoverCard.Portal>
                            <HoverCard.Content className="max-w-sm rounded-sm bg-white p-4 text-violet-950/70 shadow">
                              {feature.description}
                            </HoverCard.Content>
                          </HoverCard.Portal>
                        </HoverCard.Root>
                      </th>
                      {feature.tiers.map((tier, tierIdx) => (
                        <td
                          key={tier.title}
                          className={clsx(
                            tierIdx === feature.tiers.length - 1
                              ? 'pl-4'
                              : 'px-4',
                            'relative w-1/4 py-0 text-center text-sm text-violet-950/80'
                          )}
                        >
                          <span className="relative h-full w-full py-3">
                            {tier.value}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

const Plan = ({
  plan,
  activePeriod,
}: {
  plan: Plan
  activePeriod: 'monthly' | 'annually'
}) => {
  const { t: tl } = useTranslation('landing')
  const { t: t } = useTranslation('pricing')

  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded p-6 shadow-2xl shadow-violet-200/50',
        plan.featured
          ? 'order-first border border-violet-200 bg-white shadow-2xl shadow-violet-300/50 lg:order-none'
          : 'bg-white'
      )}
    >
      <h3 className="flex items-center text-sm font-semibold">
        <Logomark className={clsx('h-6 w-6 flex-none', 'fill-white')} />
        <span className="ml-4">{plan.name}</span>
      </h3>
      <p className="relative mt-5 flex text-3xl tracking-tight">
        {plan.price.monthly === plan.price.annually ? (
          plan.price.monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === 'annually'}
              className={clsx(
                'transition duration-300',
                activePeriod === 'annually' &&
                  'pointer-events-none translate-x-6 select-none opacity-0'
              )}
            >
              {plan.price.monthly}
            </span>
            <span
              aria-hidden={activePeriod === 'monthly'}
              className={clsx(
                'absolute left-0 top-0 transition duration-300',
                activePeriod === 'monthly' &&
                  'pointer-events-none -translate-x-6 select-none opacity-0'
              )}
            >
              {plan.price.annually}{' '}
              <span className="text-sm text-violet-700">
                ({tl('landing.pricing.youSave')} 20%)
              </span>
            </span>
          </>
        )}
      </p>
      {plan.netPrice.monthly && activePeriod === 'monthly' && (
        <p className="mt-1 text-sm lowercase text-violet-950/70">
          {plan.netPrice.monthly}
        </p>
      )}
      {plan.netPrice.annually && activePeriod === 'annually' && (
        <p className="mt-1 text-sm lowercase text-violet-950/70">
          {plan.netPrice.annually}
        </p>
      )}
      <p className="mt-3 text-sm text-violet-950/80">{plan.description}</p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className="-my-2 divide-y divide-violet-200 text-sm text-violet-950/80"
        >
          {plan.mainFeatures?.map((feature) => (
            <li key={feature.id} className="flex py-2">
              <CheckIcon className="h-6 w-6 flex-none text-violet-700" />
              <span className="ml-4">{feature.value}</span>
            </li>
          ))}
        </ul>
      </div>
      {plan.name === 'Business' && (
        <div className="mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon
                className="mt-[3px] h-5 w-5 text-violet-950/70"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 text-sm text-violet-950/70">
              <a href="tel:+48790254274">(+48) 790 254 274</a>
              <p className="mt-1">{t('pricing.tiers.business.contactHours')}</p>
            </div>
          </div>
          <div className="mt-3 flex">
            <div className="flex-shrink-0">
              <EnvelopeIcon
                className="mt-[3px] h-5 w-5 text-violet-950/70"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 text-sm text-violet-950/70">
              <p>biuro@estetify.pl</p>
            </div>
          </div>
        </div>
      )}
      <a
        href={plan.button.href}
        target={'_blank'}
        rel="noreferrer"
        className="bt-transparent text-md mt-6 flex cursor-pointer items-center justify-center whitespace-nowrap rounded border border-violet-600 px-4 py-2 font-medium leading-6 text-violet-600 shadow-sm transition-all hover:bg-violet-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-violet-700 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-violet-700 disabled:bg-violet-300 disabled:text-violet-500 disabled:opacity-30"
        id="pricing_open-app"
      >
        {plan.button.label}
      </a>
    </section>
  )
}

const EMAIL = process.env.NEXT_PUBLIC_CONTACT_EMAIL

export const PricingSection = () => {
  const { t: tl } = useTranslation('landing')
  const { t: tg } = useTranslation('generic')
  const { t } = useTranslation('pricing')
  const [activePeriod, setActivePeriod] = useState<'monthly' | 'annually'>(
    'monthly'
  )

  const plans: Plan[] = [
    {
      name: t('pricing.tiers.starter.name'),
      featured: false,
      compared: true,
      description: t('pricing.tiers.starter.description'),
      price: { monthly: '0 zł', annually: '0 zł' },
      netPrice: { monthly: '', annually: '' },
      button: {
        label: t('pricing.tiers.starter.cta.landing'),
        href: ENV.APP_URL,
      },
      mainFeatures: [
        { id: 0, value: t('pricing.tiers.starter.mainFeatures.0') },
        { id: 1, value: t('pricing.tiers.starter.mainFeatures.1') },
        { id: 2, value: t('pricing.tiers.starter.mainFeatures.2') },
      ],
    },
    {
      name: t('pricing.tiers.salon.name'),
      featured: true,
      compared: true,
      description: t('pricing.tiers.salon.description'),
      price: {
        monthly: `99 zł / ${tg('generic.monthAbbreviation')}`,
        annually: `990 zł / ${tg('generic.year')}`,
      },
      netPrice: {
        monthly: `80,49 zł ${tg('generic.net')}`,
        annually: `804,88 zł ${tg('generic.net')}`,
      },
      button: {
        label: t('pricing.tiers.salon.cta.landing'),
        href: ENV.APP_URL,
      },
      mainFeatures: [
        { id: 0, value: t('pricing.tiers.salon.mainFeatures.0') },
        { id: 1, value: t('pricing.tiers.salon.mainFeatures.1') },
        { id: 2, value: t('pricing.tiers.salon.mainFeatures.2') },
        { id: 3, value: t('pricing.tiers.salon.mainFeatures.3') },
        { id: 5, value: t('pricing.tiers.salon.mainFeatures.5') },
        { id: 4, value: t('pricing.tiers.salon.mainFeatures.4') },
      ],
    },
    {
      name: t('pricing.tiers.business.name'),
      featured: false,
      description: t('pricing.tiers.business.description'),
      compared: false,
      price: {
        monthly: t('pricing.forNegotation'),
        annually: t('pricing.forNegotation'),
      },
      netPrice: { monthly: '', annually: '' },
      button: {
        label: t('pricing.tiers.business.cta.landing'),
        href: `mailto:${EMAIL}?subject=${t('pricing.emailTitle')}&body=${t(
          'pricing.emailBody'
        )}`,
      },
    },
  ]

  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-violet-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <h2
            id="pricing-title"
            className="text-3xl font-medium tracking-tight"
          >
            {t('pricing.title')}
          </h2>
          <p className="mt-2 text-lg text-violet-950/80">
            {t('pricing.description')}
          </p>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="relative">
            <RadioGroup
              value={activePeriod}
              onChange={setActivePeriod}
              className="grid grid-cols-2"
            >
              {['monthly', 'annually'].map((period) => (
                <RadioGroup.Option
                  key={period}
                  value={period}
                  className={clsx(
                    'cursor-pointer border border-violet-300/50 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-center text-sm text-violet-950/80 outline-2 outline-offset-2 transition-colors hover:border-violet-400',
                    period === 'monthly' ? 'rounded-sm' : '-ml-px rounded-sm'
                  )}
                >
                  {period === 'monthly'
                    ? tl('landing.pricing.monthly')
                    : tl('landing.pricing.annually')}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div
              aria-hidden="true"
              className={clsx(
                'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-sm bg-violet-700 transition-all duration-300',
                activePeriod === 'monthly'
                  ? '[clip-path:inset(0_50%_0_0)]'
                  : '[clip-path:inset(0_0_0_calc(50%-1px))]'
              )}
            >
              {['monthly', 'annually'].map((period) => (
                <div
                  key={period}
                  className={clsx(
                    'py-2 text-center text-sm font-semibold text-white [&:not(:focus-visible)]:focus:outline-none',
                    period === 'annually' && '-ml-px'
                  )}
                >
                  {period === 'monthly'
                    ? tl('landing.pricing.monthly')
                    : tl('landing.pricing.annually')}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3">
          {plans.map((plan) => (
            <Plan key={plan.name} plan={plan} activePeriod={activePeriod} />
          ))}
        </div>

        <Table plans={plans} />
      </Container>
    </section>
  )
}

const FeatureIcon = (props: { icon: Icon }) => (
  <props.icon className="mx-auto h-5 w-5" />
)
